import React from 'react';
import ColumnLayout from '../core/column-layout.js';

const ContactModule = () => (
  <div className="x bcg">
    <ColumnLayout className="mt1 container--xl mxa p15">
      <div className="f1">
        <div className="x my2">
          <div className="h3 mt025">
            Want to{' '}
            <a href="mailto:info@supercluster.com" className="underline">
              work with us?
            </a>
          </div>
        </div>
      </div>
      <div className="f1" />
    </ColumnLayout>
  </div>
);

export default ContactModule;
