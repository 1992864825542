import React from 'react';
import spacetime from 'spacetime';

class CurrentTime extends React.Component {
  constructor() {
    super();
    this.state = {
      time: spacetime.now(),
    };
  }

  componentDidMount() {
    const interval = setInterval(() => {
      let time = spacetime.now();
      if (time.isDST()) {
        time = time.goto(`-${this.props.zone - 1}h`);
      } else {
        time = time.goto(`-${this.props.zone}h`);
      }
      this.setState({
        time: time,
      });
    }, 1000);
    this.setState({
      interval: interval,
    });
  }

  componentWillUnmount() {
    clearInterval(this.state.interval);
  }

  render() {
    return (
      <div className="h2 mt0 pt0 light">
        {this.state.time.unixFmt('h')}
        <spam className="time__f">:</spam>
        {this.state.time.unixFmt('mma')}
      </div>
    );
  }
}

export default CurrentTime;
