import React from 'react';
import Loadable from '@loadable/component';

import { initGA, logPageView } from '../components/layout/google.js';
import { initFB, logFBPageView } from '../components/layout/facebook.js';
import ColumnLayout from '../components/core/column-layout.js';
import ContactModule from '../components/block-content/contact-module.js';
import CurrentTime from '../components/core/current-time.js';
const Map = Loadable(() => import('../components/core/map.js'));

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    initGA();
    logPageView();
    initFB();
    logFBPageView();
  }

  render() {
    return (
      <div className="template__page">
        <Map center={[-73.99425, 40.72605]} />
        <ColumnLayout className="mt1 container--xl mxa p15">
          <div className="f1">
            <div className="h5 mt1 caps">Office</div>
            <div className="x my2">
              <div className="h3 mt025 mb0">New York</div>
              <div className="h3 mt025 mb0">
                <CurrentTime zone={5} />
              </div>
            </div>
          </div>
          <div className="f1">
            <div className="h5 mt1 caps">Visit</div>
            <div className="x my2">
              <div className="h4 mt025 mb0">155 Wooster Street</div>
              <div className="h4 mt025 mb0">3rd Floor</div>
              <div className="h4 mt025 mb0">New York, NY 10012</div>
            </div>
          </div>
          <div className="f1" />
        </ColumnLayout>
        <ContactModule />
      </div>
    );
  }
}

export default Contact;
